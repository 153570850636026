.Migration-note {
  text-align: center;
  margin-bottom: 0.5rem;
}

.Migration-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  grid-gap: 1rem;
  margin-top: 0.5rem;
}

.MigrationModal-info-box {
  margin-bottom: 0.7rem;
}

@media (max-width: 800px) {
  .Migration-cards {
    grid-template-columns: 1fr;
  }
}
