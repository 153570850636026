.Tab.block {
  display: grid;
  grid-auto-flow: column;
  background: linear-gradient(90deg, rgba(30,9,94,0.7) 0%, rgba(35,8,82,0.7) 100%);
  font-size: 0.9rem;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  background: linear-gradient(90deg, rgba(57,28,147,0.9) 0%, rgba(66,24,140,0.9) 100%);
  pointer-events: none;
}

.Tab.inline {

}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  border-bottom: 2px solid transparent;
  margin-right: 15px;
  padding-bottom: 5px;
}

.Tab.inline .Tab-option:hover {
  opacity: 1;
}

.Tab.inline .Tab-option.active {
  border-color: #ff2491;
  opacity: 1;
}
