.TradeHistory-row {
  background: linear-gradient(45deg, rgba(11,5,55,0.6) 0%, rgba(21,3,48,0.6) 100%);
  font-size: 0.9rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.TradeHistory-time {
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
}
