.Home {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.Home .App-cta {
  width: 8rem;
  position: relative;
  z-index: 2;
}

.Home a.App-cta.Home-learn-more {
  background: linear-gradient(90deg, rgba(121,74,228,1) 0%, rgba(119,59,230,1) 42%, rgba(134,41,240,1) 100%);
  box-shadow: 0 0 4px 6px rgba(119,59,230,0.2);
}

.Home a.App-cta.Home-learn-more:hover {
  background: linear-gradient(90deg, rgba(128,80,238,1) 0%, rgba(131,68,247,1) 42%, rgba(142,44,254,1) 100%);
  box-shadow: 0 0 4px 6px rgba(131,68,247,0.25);
}

.Home-learn-more {
  margin-right: 1rem;
}

.Home-wave {
  z-index: 1;
  position: fixed;
  left: 20%;
  right: 20%;
  top: 30vh;
  bottom: 0;
  mask-image: url(./img/wave-01.png);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  filter: blur(50px);
  background: linear-gradient(40deg, rgba(96,4,189,1) 0%, rgba(65,105,224,1) 22%, rgba(99,8,191,1) 23%, rgba(51,9,115,1) 37%, rgba(255,36,145,1) 38%, rgba(201,16,161,1) 50%, rgba(171,10,163,1) 59%, rgba(117,4,128,1) 67%, rgba(96,29,196,1) 68%, rgba(92,55,204,1) 84%, rgba(84,88,214,1) 100%);
  pointer-events: none;
}

@media (max-width: 700px) {
  .Home-wave {
    left: 5%;
    right: 5%;
    top: 40vh;
  }
}
