.Modal {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.Modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
}

.Modal-content {
  z-index: 20;
  position: relative;
  background: linear-gradient(45deg, rgba(7,5,57,1) 0%, rgba(34,3,66,1) 100%);
  border: 1px solid #1c0e6a;
  padding: 1rem;
  max-width: 100%;
  margin: 1rem;
}

.Modal-title-bar {
  display: grid;
  grid-template-columns: auto auto;
  padding-bottom: 0.5rem;
}

.Modal-title {
  text-align: left;
  font-size: 0.9rem;
  opacity: 0.7;
}

.Modal-close-button {
  text-align: right;
}

.Modal-close-icon {
  opacity: 0.6;
  cursor: pointer;
  text-align: right;
  display: inline-block;
}

.Modal-close-icon:hover {
  opacity: 0.9;
}
