@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'RelativeMono';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/relative/relative-faux-book-pro.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/relative/relative-faux-book-pro.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/relative/relative-faux-book-pro.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/relative/relative-faux-book-pro.woff') format('woff'), /* Modern Browsers */
       url('./fonts/relative/relative-faux-book-pro.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'RelativeMono';
  font-style: bold;
  font-weight: 500;
  src: url('./fonts/relative/relative-bold-pro.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/relative/relative-bold-pro.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/relative/relative-bold-pro.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/relative/relative-bold-pro.woff') format('woff'), /* Modern Browsers */
       url('./fonts/relative/relative-bold-pro.ttf') format('truetype'); /* Safari, Android, iOS */
}
