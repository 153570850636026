.Checkbox-icon {
  margin-right: 0.3rem;
  font-size: 0.8rem;
  color: rgba(255,36,145,1);
}

.Checkbox-icon.inactive {
  color: white;
  opacity: 0.7;
}
